<kor-drawer
  [visible]="visible"
  (visible-changed)="drawerVisibleChanged($event.target)"
  position="right"
>
  <kor-avatar
    slot="header"
    [label]="userService.user?.name"
    [info]="userService.user?.email"
    [image]="userService.user?.image"
  ></kor-avatar>
  <kor-divider spacing="s"></kor-divider>
  <kor-menu-item
    [toggle]="false"
    label="Edit Profile"
    icon="edit"
    (click)="editProfileModalVisible = true"
  ></kor-menu-item>
  <kor-menu-item
    [toggle]="false"
    label="Sign Out"
    icon="exit_to_app"
    (click)="userService.signOut(); close.emit()"
  ></kor-menu-item>
</kor-drawer>
<!-- edit profile -->
<app-edit-profile-modal
  *ngIf="editProfileModalVisible"
  (close)="editProfileModalVisible = false"
></app-edit-profile-modal>