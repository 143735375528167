<ng-container>
  <!-- image -->
  <div
    class="image"
    *ngIf="media?.image"
  >
    <img
      [src]="media?.image"
      height="auto"
      alt="An image of a bird"
      (load)="handleLoad()"
    />
  </div>
  <!-- content -->
  <div class="content">
    <!-- header -->
    <div
      class="header"
      [ngClass]="{ mobile: sharedService.screenSize === 's' }"
    >
      <!-- header summary -->
      <div class="title">
        <div
          class="taxon"
          (click)="router.navigateByUrl('/taxon/' + media?.taxonUid)"
        >
          <kor-text
            size="header-1"
            [ngClass]="{ skeleton: !mediaInfo.taxonDoc?.commonName?.en }"
          >{{ mediaInfo.taxonDoc?.commonName?.en }}</kor-text>
          <kor-text [ngClass]="{ skeleton: !mediaInfo.taxonDoc?.scientificName }">{{ mediaInfo.taxonDoc?.scientificName
            }}
          </kor-text>
        </div>
        <!-- edit button -->
        <div class="functions">
          <kor-button
            icon="edit"
            color="secondary"
            (click)="editMediaModalVisible = true"
            *ngIf="userService.user?.uid === media?.ownerUid"
          ></kor-button>
          <!-- likes -->
          <app-like-button
            [label]="media?.numLikes"
            [active]="getLikeActive()"
            (click)="setUserLike()"
          ></app-like-button>
        </div>
      </div>
      <!-- further details -->
      <div class="details">
        <div
          class="link"
          (click)="router.navigateByUrl('/user/' + mediaInfo.ownerDoc?.uid)"
        >
          <kor-icon [icon]="
              mediaInfo.ownerDoc?.image
                ? 'url(' + mediaInfo.ownerDoc?.image + ')'
                : 'face'
            "></kor-icon>
          {{ mediaInfo.ownerDoc?.name }}
        </div>
        <div
          class="link"
          (click)="
            media?.placeUid
              ? router.navigateByUrl('/place/' + media?.placeUid)
              : null
          "
        >
          <kor-icon icon="place"></kor-icon>
          {{ mediaInfo.placeName ? mediaInfo.placeName : 'Unknown location' }}
        </div>
        <div>
          <kor-icon icon="event"></kor-icon>
          {{ media?.date.toDate() | date }}
        </div>
      </div>
      <!-- description -->
      <kor-text>{{ media?.description }}</kor-text>
    </div>
  </div>
</ng-container>

<!-- edit media modal -->
<app-edit-media-modal
  *ngIf="editMediaModalVisible"
  [media]="media"
  (close)="editMediaModalVisible = false; setMedia(media!.uid)"
></app-edit-media-modal>