<kor-modal
  sticky
  [visible]="visible"
  label="Edit Profile"
  [height]="sharedService.screenSize == 's' ? '100%' : '400px'"
>
  <!-- inputs -->
  <div class="image">
    <img
      *ngIf="userService.user?.image || tempImage; else userIcon"
      [src]="tempImage ? tempImage : userService.user?.image"
    />
    <ng-template #userIcon>
      <kor-icon icon="face"></kor-icon>
    </ng-template>
    <!-- upload -->
    <kor-icon
      icon="file_upload"
      button
      (click)="fileInput.click()"
    ></kor-icon>
  </div>
  <kor-input
    label="User name"
    name="name"
    [value]="tempName ? tempName : userService.user?.name"
    (input)="setNewName($event.target)"
  ></kor-input>
  <!-- buttons -->
  <kor-button
    slot="footer"
    label="Cancel"
    color="secondary"
    (click)="closeModal()"
  ></kor-button>
  <kor-button
    slot="footer"
    label="Confirm"
    (click)="handleConfirm()"
  ></kor-button>
</kor-modal>

<!-- image file input -->
<input
  type="file"
  accept="image/*"
  (change)="importImage($event.target)"
  #fileInput
/>