<!-- image -->
<div
  class="image"
  [ngClass]="{ skeleton: !image && !icon }"
>
  <img
    #img
    *ngIf="image"
    [src]="image"
    alt="A bird photo or audio"
  />
  <kor-icon
    *ngIf="icon && !image"
    [icon]="icon"
  ></kor-icon>
  <!-- likes -->
  <app-like-button
    *ngIf="media && media?.numLikes! >= 0"
    [label]="media?.numLikes"
    [active]="getLikeActive()"
    (click)="setUserLike(); $event.stopPropagation()"
    (mouseenter)="setImageFilter('add')"
    (mouseleave)="setImageFilter('remove')"
    theme="dark"
  ></app-like-button>
</div>
<!-- text -->
<div class="text">
  <kor-text
    size="header-1"
    [ngClass]="{ skeleton: !header }"
  >{{ header }}
  </kor-text>
  <kor-text [ngClass]="{ skeleton: !label }">{{ label }}</kor-text>
</div>