<div class="banner">
  <app-empty-state
    header="What the bird?"
    label="Upload a bird picture and we tell you what it is"
    [image]="illustrationUrl"
  >
    <!-- button -->
    <kor-button
      label="Upload"
      (click)="router.navigateByUrl('/upload')"
    ></kor-button>
  </app-empty-state>
</div>
<!-- recent media carousel -->
<div class="carousel">
  <kor-text size="header-1">Recent Media</kor-text>
  <kor-grid
    fit-content
    [columns]="sharedService.screenSize === 's' ? '8' : '4'"
  >
    <ng-container *ngIf="featuredTaxonList.length > 0; else skeleton">
      <app-media-card
        *ngFor="let media of recentMediaList"
        [media]="media"
        (click)="router.navigateByUrl('/media/' + media.uid)"
      ></app-media-card>
    </ng-container>
  </kor-grid>
</div>
<!-- featured taxon carousel -->
<div class="carousel">
  <kor-text size="header-1">Featured Species</kor-text>
  <kor-grid
    fit-content
    [columns]="sharedService.screenSize === 's' ? '8' : '4'"
  >
    <ng-container *ngIf="featuredTaxonList.length > 0; else skeleton">
      <app-media-card
        *ngFor="let taxon of featuredTaxonList"
        [header]="taxon.commonName?.en"
        [label]="taxon.scientificName"
        [image]="taxon.thumbnail"
        icon="assignment"
        (click)="router.navigateByUrl('/taxon/' + taxon.uid)"
      ></app-media-card>
    </ng-container>
  </kor-grid>
</div>
<!-- featured users carousel -->
<div class="carousel">
  <kor-text size="header-1">Featured Users</kor-text>
  <kor-grid
    fit-content
    columns="8"
  >
    <ng-container *ngIf="featuredUsersList.length > 0; else skeleton">
      <app-media-card
        class="circle"
        *ngFor="let user of featuredUsersList"
        [header]="user.name"
        label="{{(user.placeUid?.length ? user.placeUid?.length : '0')?.toString()}} species"
        [image]="user.image"
        icon="person"
        (click)="router.navigateByUrl('/user/' + user.uid)"
      ></app-media-card>
    </ng-container>
  </kor-grid>
</div>
<!-- skeleton -->
<ng-template #skeleton>
  <app-media-card *ngFor="let item of [].constructor(8)"></app-media-card>
</ng-template>