<!-- page header -->
<app-page-header
  icon="person"
  [image]="user?.image"
  [header]="user?.name"
  [ngClass]="{ mobile: sharedService.screenSize === 's' }"
>
</app-page-header>
<!-- media grid -->
<kor-grid fit-content [columns]="sharedService.screenSize === 's' ? '2' : '4'">
  <app-media-card
    *ngFor="let media of mediaList"
    [media]="media"
    (click)="router.navigateByUrl('/media/' + media.uid)"
  >
  </app-media-card>
</kor-grid>
