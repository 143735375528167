<kor-app-bar
  [mobile]="sharedService.screenSize === 's'"
  [label]="sharedService.screenSize === 's' ? sharedService.appLabel : ''"
>
  <!-- mobile logo -->
  <img
    *ngIf="sharedService.screenSize !== 's' || router.url === '/home'"
    height="24"
    src="../favicon.ico"
    [slot]="sharedService.screenSize === 's' ? 'left' : ''"
    alt=""
    (click)="router.navigateByUrl('')"
  />
  <!-- desktop label -->
  <kor-text *ngIf="sharedService.screenSize !== 's'" size="header-1">
    {{ sharedService.screenSize === 's' ? sharedService.appLabel : 'Tuca' }}
  </kor-text>
  <!-- back icon -->
  <kor-icon
    *ngIf="router.url !== '/home'"
    slot="left"
    icon="arrow_back"
    button
    (click)="location.back()"
  ></kor-icon>
  <!-- user identifier -->
  <kor-avatar
    [slot]="sharedService.screenSize === 's' ? 'right' : 'functions'"
    [image]="userService.user?.image"
    (click)="
      !userService.user
        ? (userService.signInModalVisible = true)
        : (userService.userDrawerVisible = true)
    "
  ></kor-avatar>
</kor-app-bar>

<!-- sign in modal -->
<app-sign-in-modal
  *ngIf="userService.signInModalVisible"
  (close)="userService.signInModalVisible = false"
></app-sign-in-modal>
<!-- user drawer -->
<app-user-drawer
  *ngIf="userService.userDrawerVisible"
  (close)="userService.userDrawerVisible = false"
></app-user-drawer>
