<kor-modal
  sticky
  [visible]="visible"
  label="Edit Media"
  [height]="sharedService.screenSize == 's' ? '100%' : '400px'"
>
  <!-- inputs -->
  <kor-input
    readonly
    label="Place"
    [value]="tempPlaceName"
    style="pointer-events: all"
    (click)="selectPlaceModalVisible = true"
  >
    <kor-icon
      button
      icon="edit"
      slot="functions"
    >
    </kor-icon>
  </kor-input>
  <kor-input
    label="Date of capture"
    no-clear
    type="date"
    [value]="media?.date?.toDate() | date: 'yyyy-MM-dd'"
    (input)="setDate($event.target)"
  ></kor-input>
  <kor-textarea
    rows="5"
    label="Description"
    [value]="media?.description"
    (value-changed)="setDescription($event.target)"
  ></kor-textarea>
  <!-- TODO: add species selection UI -->
  <!-- cta confirm button -->
  <kor-button
    slot="footer"
    label="Delete"
    color="tertiary"
    (click)="handleDelete()"
  ></kor-button>
  <kor-button
    slot="footer"
    label="Cancel"
    color="secondary"
    (click)="closeModal()"
  ></kor-button>
  <kor-button
    slot="footer"
    label="Confirm"
    (click)="handleConfirm()"
  ></kor-button>
</kor-modal>
<!-- select place modal -->
<app-select-place-modal
  *ngIf="selectPlaceModalVisible"
  (select)="setPlace($event)"
  (close)="selectPlaceModalVisible = false"
></app-select-place-modal>