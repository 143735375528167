<kor-modal
  label="Sign In"
  [visible]="visible"
  (visible-changed)="modalVisibleChanged($event.target)"
  [height]="sharedService.screenSize === 's' ? '100%' : '560px'"
>
  <!-- illustration -->
  <app-empty-state
    label="Welcome to Tuca! Sign in or register with your social media account."
    image="assets/illustrations/hello.png"
  ></app-empty-state>
  <kor-menu-item
    label="Sign in with Google"
    icon="url(https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-google-icon-logo-png-transparent-svg-vector-bie-supply-14.png)"
    [toggle]="false"
    (click)="signIn('google')"
  ></kor-menu-item>
  <kor-menu-item
    label="Sign in with Facebook"
    icon="url(https://www.freepnglogos.com/uploads/facebook-logo-13.png)"
    [toggle]="false"
    (click)="signIn('facebook')"
  ></kor-menu-item>
</kor-modal>