<ng-container *ngIf="media?.image">
  <img
    [src]="media?.image"
    alt=""
  />
  <ng-container *ngIf="!media?.taxonUid; then selectTaxon; else details"></ng-container>
  <!-- 2. select taxon -->
  <ng-template #selectTaxon>
    <kor-text size="header-1">Select taxon</kor-text>
    <!-- taxon list -->
    <div class="taxon-list">
      <kor-input
        type="search"
        (keydown.enter)="handleSearch($event.target)"
        label="Search for another taxon..."
      >
        <kor-icon
          icon="search"
          slot="functions"
          (click)="handleSearch($event.target)"
        ></kor-icon>
      </kor-input>
      <!-- suggested taxon -->
      <ng-container *ngIf="
          searchTaxonList.length > 0;
          then searchTaxon;
          else suggestedTaxon
        "></ng-container>
      <ng-template #suggestedTaxon>
        <ng-container *ngIf="!fetching; else skeleton">
          <app-suggestion-card
            *ngFor="let taxon of suggestedTaxonList"
            [image]="taxon.thumbnail"
            [header]="taxon.commonName?.en"
            [label]="taxon.scientificName"
            [confidence]="getTaxonConfidence(taxon.uid)"
            (click)="base64Image ? setTaxon(taxon.uid) : ''"
          ></app-suggestion-card>
        </ng-container>
        <ng-template #skeleton>
          <app-suggestion-card *ngFor="let item of [].constructor(8)"></app-suggestion-card>
        </ng-template>
      </ng-template>
      <!-- search taxon -->
      <ng-template #searchTaxon>
        <app-suggestion-card
          *ngFor="let taxon of searchTaxonList"
          [image]="taxon.thumbnail"
          [header]="taxon.commonName?.en"
          [label]="taxon.scientificName"
          (click)="media!.taxonUid = taxon.uid; setTaxon(taxon.uid)"
        ></app-suggestion-card>
      </ng-template>
    </div>
  </ng-template>
  <!-- 3. details -->
  <ng-template #details>
    <kor-text size="header-1">Confirm details</kor-text>
    <kor-input
      readonly
      label="Place"
      [value]="tempPlaceName"
      style="pointer-events: all"
      (click)="selectPlaceModalVisible = true"
    >
      <kor-icon
        button
        icon="edit"
        slot="functions"
      >
      </kor-icon>
    </kor-input>
    <kor-input
      label="Date of capture"
      no-clear
      type="date"
      [value]="media?.date.toDate() | date: 'yyyy-MM-dd'"
      (input)="setDate($event.target)"
    ></kor-input>
    <kor-textarea
      rows="3"
      label="Description"
      [value]="media?.description"
      (input)="setDescription($event.target)"
    ></kor-textarea>
    <!-- cta confirm button -->
    <kor-button
      label="Confirm"
      class="cta"
      [ngClass]="{ mobile: sharedService.screenSize === 's' }"
      (click)="handleConfirm()"
    ></kor-button>
  </ng-template>
</ng-container>
<!-- 1. select image -->
<input
  type="file"
  accept="image/*"
  (change)="importImage($event.target)"
  #fileInput
/>
<!-- select place modal -->
<app-select-place-modal
  *ngIf="selectPlaceModalVisible"
  (select)="setPlace($event)"
  (close)="selectPlaceModalVisible = false"
></app-select-place-modal>
<!-- placeholder -->
<ng-container *ngIf="!media?.image">
  <app-empty-state
    header="What the bird?"
    label="Upload a bird picture and we tell you what it is"
  >
    <!-- button -->
    <kor-button
      label="Upload"
      (click)="fileInput.click()"
    ></kor-button>
  </app-empty-state>
</ng-container>