<kor-modal
  sticky
  label="Select place"
  [visible]="visible"
  [height]="sharedService.screenSize === 's' ? '100%' : '480px'"
>
  <kor-icon
    icon="close"
    button
    slot="functions"
    (click)="close.emit()"
  ></kor-icon>
  <!-- search -->
  <kor-input
    autofocus
    no-clear
    label="Search for place..."
    type="search"
    (keydown.enter)="findPlace($event.target)"
    #searchEl
  >
    <kor-icon
      icon="search"
      slot="functions"
      (click)="findPlace($event.target)"
    ></kor-icon>
  </kor-input>
  <kor-menu-item
    *ngFor="let place of suggestedPlaces"
    icon="place"
    [label]="place.formatted_address"
    (click)="handleSelect(place.place_id)"
  ></kor-menu-item>
</kor-modal>